import styled from '@emotion/styled'
import { Chip, ChipProps, IconButton } from '@mui/material'
import { DeleteIcon } from '@/components/icon/DeleteIcon'
import { functionalColor, textColor } from '@/utils/themeConfigs/customTheme'

const CustomChip = styled(Chip)`
  background-color: ${functionalColor.lightPink};
  border-radius: 4px;
  padding: 5px 8px 5px 0;
  color: ${textColor.dark};
  .MuiChip-deleteIcon {
    margin: 0;
    padding: 0;
  }
`

const CustomIconButton = styled(IconButton)``

type BasicChipProps = ChipProps

export const BasicChip = ({ ...props }: BasicChipProps) => {
  return (
    <CustomChip
      deleteIcon={
        <CustomIconButton>
          <DeleteIcon color={textColor.red} />
        </CustomIconButton>
      }
      {...props}
    />
  )
}
