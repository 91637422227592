import { useState } from 'react'
import styled from '@emotion/styled'
import { IconButton } from '@mui/material'

import { ExpandIcon } from '@/components/icon/ExpandIcon'
import { BasicButtonWithIcon } from '@/components/uis/Button/BasicButtonWithIcon'
import { useDisclosure } from '@/utils/hooks/useDisclosure'
import { outlineColor } from '@/utils/themeConfigs/customTheme'
import { DuplicateChatModal } from '@/features/user/teams/components/uis/modal/DuplicateChatModal'

import { useDuplicateChat } from '../../hooks/useDuplicateChat'

const DuplicateIconButton = styled(IconButton)`
  border-radius: 25px;
  border: solid 1px ${outlineColor.red};
`

type Props = {
  token: string
  chatRoomId: string
  isMobile?: boolean
}

export const DuplicateChat = ({ token, chatRoomId, isMobile }: Props) => {
  const { isOpen, handleOpen, handleClose } = useDisclosure()
  const [addresses, setAddresses] = useState<string[]>([])
  const { mutate } = useDuplicateChat()

  const handleChange = (value: string[]) => {
    setAddresses(value)
  }

  const handleInitAndClose = () => {
    setAddresses([])
    handleClose()
  }

  const handleSubmit = () => {
    if (addresses) {
      mutate(
        { token, id: chatRoomId, addresses },
        {
          onSuccess: () => handleInitAndClose(),
        },
      )
    }
  }

  return (
    <>
      {isMobile ? (
        <DuplicateIconButton onClick={handleOpen}>
          <ExpandIcon />
        </DuplicateIconButton>
      ) : (
        <BasicButtonWithIcon
          label='コピーを送る'
          icon={<ExpandIcon />}
          variant='outlined'
          onClick={handleOpen}
        />
      )}
      <DuplicateChatModal
        isMobile={isMobile}
        isOpen={isOpen}
        value={addresses}
        handleClose={handleInitAndClose}
        handleChange={handleChange}
        handleSubmit={handleSubmit}
      />
    </>
  )
}
