import React from 'react'
import styled from '@emotion/styled'
import { Autocomplete, Box, TextField } from '@mui/material'

import {
  bgColor,
  fontSize,
  functionalColor,
  outlineColor,
  textColor,
} from '@/utils/themeConfigs/customTheme'

import { BasicChip } from './BasicChip'
import { BasicLabel } from './BasicLabel'
import { InputSelectOptions } from '@/utils/types/common'

const Wrapper = styled.div<{ width?: string; maxWidth?: string }>`
  width: ${({ width }) => width || '100%'};
  max-width: ${({ maxWidth }) => maxWidth || 'none'};
`

const BasicAutocomplete = styled(Autocomplete, {
  shouldForwardProp: (props) => props !== 'hasError' && props !== 'maxHeight',
})<{ hasError?: boolean; maxHeight?: string }>`
  .MuiOutlinedInput-root {
    border: solid 1px
      ${({ hasError }) => (hasError ? functionalColor.red : outlineColor.lightGray)};
    border-radius: 6px;
    background-color: ${bgColor.white};
  }

  .MuiOutlinedInput-notchedOutline {
    border: none;
  }

  .MuiInputBase-root {
    padding: 8px 12px !important;
    gap: 8px;
    font-size: ${fontSize.sm};

    max-height: ${({ maxHeight }) => maxHeight ?? 'none'};
    overflow-y: scroll;

    input {
      padding: 0 !important; //TODO:手隙でimportant外す
    }
  }
`

const CustomTextField = styled(TextField)`
  .MuiInputBase-input {
    font-size: ${fontSize.sm};

    &::placeholder {
      color: ${textColor.lightGray};
      opacity: 1;
    }
  }
`

const ErrorMessage = styled.div`
  color: ${functionalColor.red};
  font-size: ${fontSize.xxs};
  margin-top: 2px;
`

export type BasicAutocompleteProps = {
  id?: string
  label?: string
  options: InputSelectOptions
  placeholder?: string
  isRequired?: boolean
  errorMessage?: string
  errors?: boolean
  width?: string
  maxWidth?: string
  maxHeight?: string
} & Omit<Parameters<typeof Autocomplete>[0], 'renderInput'>

export const BasicAutocompleteWithChips = (props: BasicAutocompleteProps) => {
  const {
    id,
    label,
    options,
    placeholder,
    isRequired,
    errorMessage,
    errors,
    size,
    width,
    maxWidth,
    maxHeight,
    freeSolo,
    ...prop
  } = props
  return (
    <Wrapper width={width} maxWidth={maxWidth}>
      {label && <BasicLabel label={label} htmlFor={id} isRequired={isRequired} />}
      <BasicAutocomplete
        maxHeight={maxHeight}
        multiple
        freeSolo={freeSolo}
        options={options}
        {...prop}
        renderTags={(value, props) => {
          return (
            <Box>
              {typeof value === 'object' &&
                value.map((val, index) => (
                  <React.Fragment key={index}>
                    <BasicChip
                      {...props({ index })}
                      key={val as string}
                      size={size ?? 'small'}
                      label={val as string}
                    />
                  </React.Fragment>
                ))}
            </Box>
          )
        }}
        renderInput={(params) => (
          <CustomTextField placeholder={placeholder} {...params} size={size ?? 'small'} />
        )}
      />
      {errors && errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
    </Wrapper>
  )
}
