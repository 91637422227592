import { useRouter } from 'next/router' // shallowがnext/navigationにない
import { useMutation, useQueryClient } from '@tanstack/react-query'

import { API } from '@/utils/apis/endpoints'
import { useApiClient } from '@/utils/hooks/useApiClient'
import { MutationKeys } from '@/utils/apis/api-keys'
import { CreateImageRequestBody } from '../types'

type ChatImageResponse = {
  chat_room_id: string
  image: string
  text: string
  type: 'answer' | 'error'
}

type MutationFnArgument = {
  token: string
  question: string
  model_id: number
  chatRoomId?: string
  flag: number
}

export const useCreateImage = () => {
  const { apiClient } = useApiClient()

  const queryClient = useQueryClient()
  const router = useRouter()

  return useMutation({
    mutationFn: ({ token, question, chatRoomId, model_id, flag }: MutationFnArgument) => {
      const requestBody: CreateImageRequestBody = {
        question: question,
        llm_model_id: model_id,
        flag,
      }

      if (chatRoomId) {
        requestBody.chat_room_id = chatRoomId
      }

      return apiClient(token)
        .post<ChatImageResponse>(API.chatImage, requestBody)
        .then((res) => res.data)
    },
    onSuccess: async ({ chat_room_id }) => {
      // URLの変更
      const { chatRoomId } = router.query
      if (!chatRoomId) {
        await router.push('', `/${chat_room_id}`, { shallow: true })
      }

      // 履歴一覧の反映
      await queryClient.invalidateQueries({ queryKey: MutationKeys.history })
    },
    onSettled: async () => {},
  })
}
