import { useMutation } from '@tanstack/react-query'
import { MutationKeys } from '@/utils/apis/api-keys'
import { API } from '@/utils/apis/endpoints'
import { useApiClient } from '@/utils/hooks/useApiClient'

export const useDuplicateChat = () => {
  const { apiClient } = useApiClient()

  return useMutation({
    mutationKey: MutationKeys.duplicateChat,
    mutationFn: async ({
      token,
      id,
      addresses,
    }: {
      token: string
      id: string
      addresses: string[]
    }) => await apiClient(token).post(API.duplicateChat(id), addresses),
  })
}
