import { accentColor, bgColor } from '@/utils/themeConfigs/customTheme'

export const FileUploadIcon = ({ disabled }: { disabled: boolean }) => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect
        width='24'
        height='24'
        rx='2'
        fill={disabled ? bgColor.mediumGray : accentColor.primaryRed}
      />
      <path
        d='M18.7411 9.30162H18.3473V8.5443C18.3474 8.4446 18.3278 8.34585 18.2898 8.25372C18.2517 8.16158 18.1958 8.07786 18.1253 8.00736C18.0548 7.93686 17.971 7.88096 17.8789 7.84286C17.7868 7.80477 17.688 7.78522 17.5883 7.78534H11.3466C11.1827 7.78533 11.0232 7.73225 10.892 7.63407L9.87533 6.8737C9.74406 6.77568 9.58463 6.72271 9.4208 6.72266H6.41099C6.2097 6.72266 6.01665 6.80262 5.87432 6.94495C5.73199 7.08728 5.65203 7.28033 5.65203 7.48162V9.30209H5.25847C5.14867 9.30216 5.04019 9.32606 4.94052 9.37213C4.84085 9.4182 4.75236 9.48535 4.68116 9.56894C4.60997 9.65254 4.55776 9.75059 4.52814 9.85632C4.49852 9.96205 4.49219 10.073 4.5096 10.1814L5.54906 16.6358C5.57777 16.8139 5.66895 16.9759 5.80626 17.0929C5.94358 17.2098 6.11805 17.274 6.29841 17.274H17.7011C17.8815 17.274 18.056 17.2098 18.1933 17.0929C18.3306 16.9759 18.4218 16.8139 18.4505 16.6358L19.4904 10.1809C19.5078 10.0724 19.5015 9.9615 19.4718 9.85574C19.4422 9.74997 19.3899 9.6519 19.3187 9.5683C19.2474 9.4847 19.1589 9.41755 19.0592 9.37151C18.9594 9.32547 18.8509 9.30163 18.7411 9.30162ZM6.08569 9.30162V8.5443C6.08575 8.45805 6.12004 8.37534 6.18103 8.31435C6.24203 8.25336 6.32473 8.21906 6.41099 8.219H17.5885C17.6748 8.21906 17.7575 8.25336 17.8185 8.31435C17.8795 8.37534 17.9138 8.45805 17.9138 8.5443V9.30209L6.08569 9.30162Z'
        fill='white'
      />
    </svg>
  )
}
