export const ExpandIcon = () => {
  return (
    <svg
      width='21'
      height='20'
      viewBox='0 0 21 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M8.05032 2.64844H4.12875C3.04542 2.64844 2.16797 3.52589 2.16797 4.60922V16.3739C2.16797 17.4573 3.04542 18.3347 4.12875 18.3347H15.8935C16.9768 18.3347 17.8542 17.4573 17.8542 16.3739V11.9622'
        stroke='#8C0026'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M12.4609 1.66797H18.8335V8.04052'
        stroke='#8C0026'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M18.8353 1.66797L10.9922 9.51111'
        stroke='#8C0026'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}
