import styled from '@emotion/styled'
import { Box, Typography } from '@mui/material'

import { BasicButton } from '@/components/uis/Button/BasicButton'
import { BasicAutocompleteWithChips } from '@/components/uis/Input/BasicAutocompleteWithChips'
import { BasicModal } from '@/components/uis/Modal/BasicModal'
import { DuplicateChatModalProps } from '@/features/user/chat/types'
import { mediaQuery } from '@/utils/helpers/breakpoint'
import { fontSize, fontWeight, textColor } from '@/utils/themeConfigs/customTheme'

const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 16px;
  color: ${textColor.dark};
  ${mediaQuery('tab')} {
    padding: 0;
  }
`

const Title = styled(Typography)`
  font-size: ${fontSize.lg};
  font-weight: ${fontWeight.bold};
`

const ActionItems = styled(Box)`
  display: flex;
  gap: 16px;
  justify-content: center;
  padding: 16px 0 0;
  ${mediaQuery('tab')} {
    flex-direction: column-reverse;
    align-items: center;
  }
`

export const DuplicateChatModal = ({
  isMobile,
  isOpen,
  value,
  handleClose,
  handleChange,
  handleSubmit,
}: DuplicateChatModalProps) => {
  return (
    <BasicModal
      open={isOpen}
      onClose={handleClose}
      innerWidth='extraLarge'
      wrapperWidth='extraLarge'
    >
      <Container>
        <Title>コピーを送る</Title>
        <BasicAutocompleteWithChips
          label='コピーを送る人のユーザーID'
          freeSolo //自由入力許可
          disableClearable //右側の全削除ボタン
          size='small'
          maxHeight={isMobile ? '300px' : '390px'}
          options={[]} //TODO:将来的にオートコンプリート機能を実現するならここ
          value={value}
          onChange={(e, v) => handleChange(v as string[])}
        />
        <ActionItems>
          <BasicButton
            label='キャンセル'
            variant='outlined'
            width='160px'
            height='43px'
            onClick={handleClose}
          />
          <BasicButton
            label='送信'
            width='160px'
            height='43px'
            onClick={handleSubmit}
            disabled={value.length === 0}
          />
        </ActionItems>
      </Container>
    </BasicModal>
  )
}
