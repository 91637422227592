import React from 'react'
import { useEffect, useRef } from 'react'
import { usePathname } from 'next/navigation'
import { useRouter } from 'next/router'
import { Box, IconButton, TextField } from '@mui/material'
import styled from '@emotion/styled'
import { SendIcon } from '@/components/icon/SendIcon'
import { MicrophoneIcon } from '@/components/icon/MicrophoneIcon'
import { DeleteIcon } from '@/components/icon/DeleteIcon'
import { DownloadIcon } from '@/components/icon/DownloadIcon'
import { useAuthContext } from '@/context/AuthContext'
import {
  accentColor,
  functionalColor,
  outlineColor,
} from '@/utils/themeConfigs/customTheme'
import { mediaQuery } from '@/utils/helpers/breakpoint'
import { getChatRoomId } from '@/utils/helpers/getChatRoomId'
import { BasicButtonWithIcon } from '@/components/uis/Button/BasicButtonWithIcon'
import { DuplicateChat } from '@/features/user/teams/components/uis/DuplicateChat'
import { BasicInputFile } from '@/components/uis/Input/BasicInputFile'

import { LlmModelSetting } from './LlmModelSetting'
import { LlmModel, LlmModels } from '../../../types'
import {
  ChangeLlmModelType,
  HandleModalSubmitArgumentType,
} from '../../../hooks/useLlmModel'
import { HandleSendChatType } from '../../page/Chat'
import { useFetchTxt, useTxtDownload } from '../../../hooks/useFetchTxt'

const Container = styled(Box)`
  max-width: 960px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding-top: 8px;
`
const ChatInputWrap = styled(Box)`
  display: flex;
`

const ChatInputContainer = styled('div')<{ hasFile: boolean }>`
  display: flex;
  flex-direction: ${({ hasFile }) => (hasFile ? 'column' : 'row')};
  border: 1px;
  border-style: solid;
  border-radius: 6px;
  border-color: ${outlineColor.lightGray};
  padding: 8px;
  align-items: center;
  width: calc(100% - 48px);
  position: relative;
`

const ChatInput = styled(TextField)`
  padding: 0;
  margin-right: 28px;
  width: calc(100% - 28px);
  & .MuiInputBase-root {
    padding: 0;
  }
  & .MuiInputBase-input {
    font-weight: 400;
    line-height: 1.448;
  }
  & .MuiFormHelperText-root {
    color: ${functionalColor.red};
  }
`

const SendButton = styled(IconButton)`
  padding: 0;
  width: 22px;
  height: 20px;
  opacity: ${({ disabled }) => (disabled ? '0.2' : '1')};
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
`

const MicrophoneButtonContainer = styled(Box)`
  display: flex;
  place-items: center;
  margin-left: 8px;
`

const MicrophoneButton = styled(IconButton)`
  border-radius: 22px;
  padding: 2px;
  background-color: ${accentColor.lightRed}!important;
  width: 40px;
  height: 40px;
  &.Mui-disabled {
    opacity: 0.2;
  }
`
const ButtonWrap = styled(Box)`
  display: flex;
  align-items: center;
  gap: 32px;
  ${mediaQuery('tab')} {
    justify-content: space-between;
    gap: 8px;
  }
`
const ChatInputWrapper = styled(Box)`
  width: calc(100% - 64px);
  margin-top: 8px;
  background-color: red;
`

type Props = {
  handleSendChat: HandleSendChatType
  chatInputValue: string
  handleChangeChatInput: (text: string) => void
  maxLength: number
  isValid: boolean
  changeLlmModel: ChangeLlmModelType
  selectedLlmModel: LlmModel | undefined
  allowFileAttachments: boolean
  llmModels: LlmModels
  disabledLlmModel?: boolean
  isAnswering: boolean
  isOpen: boolean
  isMobile: boolean | undefined
  visibleTextExport: boolean
  handleCloseModal: () => void
  handleModalSubmit: HandleModalSubmitArgumentType
  isSpeaking: boolean
  isRecording: boolean
  startRecording: () => void
  stopRecording: () => void
  promptId?: string
  isIndexModalOpen: boolean
  handleIndexModalClose: () => void
  selectedIndex: boolean
  selectedFile: File | null
  setSelectedFile: (file: File | null) => void
}

export const ChatForm = ({
  handleSendChat,
  chatInputValue,
  handleChangeChatInput,
  maxLength,
  isValid,
  changeLlmModel,
  selectedLlmModel,
  allowFileAttachments,
  llmModels,
  disabledLlmModel,
  isAnswering,
  isOpen,
  isMobile,
  visibleTextExport,
  handleCloseModal,
  handleModalSubmit,
  isSpeaking,
  isRecording,
  startRecording,
  stopRecording,
  promptId,
  isIndexModalOpen,
  handleIndexModalClose,
  selectedIndex,
  selectedFile,
  setSelectedFile,
}: Props) => {
  const pathname = usePathname()
  const router = useRouter()

  const chatRoomId = getChatRoomId({ router, pathname })
  const { user } = useAuthContext()

  const { data: res, mutate } = useFetchTxt()

  const { createAndDownload } = useTxtDownload()
  const canSendChat = !chatInputValue.trim() || isAnswering || !isValid

  const inputRef = useRef<HTMLInputElement>(null)

  const handleRecordButtonClick = () => {
    if (isRecording) {
      stopRecording()
    } else {
      startRecording()
    }
  }

  const handleChangeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    handleChangeChatInput(event.target.value)
  }

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (
      event.key === 'Enter' &&
      event.shiftKey &&
      !event.ctrlKey &&
      !event.altKey &&
      !event.metaKey &&
      !event.nativeEvent.isComposing
    ) {
      event.preventDefault()

      // バリデーションチェック
      // Shift＋Enterで改行されるのを防ぐためにここに記載
      if (!isValid) return

      sendChat()
    }
  }

  const sendChat = () => {
    handleSendChat()
  }

  useEffect(() => {
    if (selectedFile && inputRef.current) {
      inputRef.current.focus()
      const length = inputRef.current.value.length
      inputRef.current.setSelectionRange(length, length)
    }
  }, [selectedFile])

  useEffect(() => {
    if (res) {
      const header = res.headers['content-disposition'] as string
      const matches = header.match(/filename="?(.+?)"?($|;)/)
      const filename = matches ? matches[1] : 'unknown'
      createAndDownload(res.data, filename)
    }
  }, [res])

  const handleDownloadClick = () => {
    try {
      mutate({
        token: user?.token ?? '',
        chatRoomId: chatRoomId || '',
      })
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <Container>
      <ButtonWrap>
        <LlmModelSetting
          promptId={promptId}
          selectedIndex={selectedIndex}
          changeLlmModel={changeLlmModel}
          selectedLlmModel={selectedLlmModel}
          llmModels={llmModels}
          isModalOpen={isOpen}
          handleCloseModal={handleCloseModal}
          handleModalSubmit={handleModalSubmit}
          isIndexModalOpen={isIndexModalOpen}
          handleIndexModalClose={handleIndexModalClose}
          disabled={disabledLlmModel}
        />
        {!isMobile && visibleTextExport && (
          <BasicButtonWithIcon
            label='会話をエクスポート'
            icon={<DownloadIcon />}
            variant='outlined'
            onClick={() => {
              void handleDownloadClick()
            }}
          />
        )}
        {chatRoomId && !pathname.includes('teams') && (
          <DuplicateChat
            isMobile={isMobile}
            token={user?.token ?? ''}
            chatRoomId={chatRoomId}
          />
        )}
      </ButtonWrap>
      <ChatInputWrap>
        <ChatInputContainer hasFile={!!selectedFile}>
          {allowFileAttachments && (
            <BasicInputFile
              disabled={selectedIndex}
              selectedFile={selectedFile}
              setSelectedFile={setSelectedFile}
            />
          )}
          {selectedFile && <ChatInputWrapper />}
          <ChatInput
            placeholder='気になることを質問してみましょう'
            variant='standard'
            InputProps={{ disableUnderline: true }}
            value={chatInputValue}
            onChange={handleChangeInput}
            inputRef={inputRef}
            inputProps={{
              autoComplete: 'off',
            }}
            multiline
            maxRows={isMobile ? 7 : 10}
            onKeyDown={handleKeyDown}
            disabled={isRecording}
            helperText={!isValid ? `${maxLength}文字以内で入力してください` : undefined}
          />
          <SendButton onClick={sendChat} disabled={canSendChat}>
            <SendIcon />
          </SendButton>
        </ChatInputContainer>
        <MicrophoneButtonContainer>
          <MicrophoneButton
            onClick={handleRecordButtonClick}
            disabled={isAnswering || isSpeaking}
          >
            {isRecording ? <DeleteIcon /> : <MicrophoneIcon />}
          </MicrophoneButton>
        </MicrophoneButtonContainer>
      </ChatInputWrap>
    </Container>
  )
}
