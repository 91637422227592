import React, { useEffect, useRef } from 'react'
import styled from '@emotion/styled'
import { Box, IconButton, Typography } from '@mui/material'
import {
  bgColor,
  outlineColor,
  textColor,
  fontSize,
} from '@/utils/themeConfigs/customTheme'
import { FileUploadIcon } from '@/components/icon/FileUploadIcon'
import { CloseIcon } from '@/components/icon/CloseIcon'
import { ACCEPT_FILE_TYPE } from '@/utils/constants/acceptFileType'

const FileUploadButton = styled(IconButton)`
  padding: 0;
  margin-right: 8px;
`

const CloseButton = styled(IconButton)`
  background-color: ${bgColor.white};
  width: 20px;
  height: 20px;
  padding: 0;
  border-radius: 50%;
  margin-left: 8px;
  border: solid 1px ${outlineColor.lightGray};
  &:hover {
    background-color: ${bgColor.white};
  }
`

const FileWrap = styled('div')`
  display: flex;
  align-items: center;
  width: ${({ hasFileName }: { hasFileName: boolean }) =>
    hasFileName ? '100%' : 'auto'};
  padding: 0;
`

const FileNameWrap = styled(Box)`
  display: flex;
  align-items: center;
  padding: 2px 6px;
  background-color: ${bgColor.lightestGray};
  border: solid 1px ${outlineColor.lightGray};
  border-radius: 6px;
  position: relative;
  max-width: calc(100% - 64px);
  overflow: hidden;
`

const FileName = styled(Typography)`
  font-size: ${fontSize.xs};
  color: ${textColor.gray};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
`

const Input = styled('input')`
  display: none;
`

type BasicInputFileProps = {
  disabled: boolean
  selectedFile: File | null
  setSelectedFile: (file: File | null) => void
}

export const BasicInputFile = ({
  disabled,
  setSelectedFile,
  selectedFile,
}: BasicInputFileProps): JSX.Element => {
  const fileInputRef = useRef<HTMLInputElement>(null)

  const handleFileChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    const fileList = event.target.files
    setSelectedFile(fileList && fileList.length > 0 ? fileList[0] : null)
  }

  const handleButtonClick: React.MouseEventHandler<HTMLButtonElement> = () => {
    fileInputRef.current?.click()
  }

  const handleFileRemove: React.MouseEventHandler<HTMLButtonElement> = () => {
    if (fileInputRef.current) {
      fileInputRef.current.value = ''
    }
    setSelectedFile(null)
  }

  useEffect(() => {
    // 画面遷移時等にchatLogic側でselectedFile===nullになるので
    // refのvalueも合わせる
    if (fileInputRef.current) {
      if (!selectedFile && fileInputRef.current.value) fileInputRef.current.value = ''
    }
  }, [selectedFile])

  return (
    <FileWrap hasFileName={!!selectedFile}>
      <Input
        ref={fileInputRef}
        type='file'
        onChange={handleFileChange}
        accept={ACCEPT_FILE_TYPE}
      />

      <FileUploadButton onClick={handleButtonClick} disabled={disabled}>
        <FileUploadIcon disabled={disabled} />
      </FileUploadButton>

      {selectedFile && (
        <FileNameWrap>
          <FileName>{selectedFile.name}</FileName>
          <CloseButton onClick={handleFileRemove}>
            <CloseIcon size={8} />
          </CloseButton>
        </FileNameWrap>
      )}
    </FileWrap>
  )
}
