import { useCallback, useEffect, useState } from 'react'
import { usePathname, useRouter, useSearchParams } from 'next/navigation'

import { useDisclosure } from '@/utils/hooks/useDisclosure'
import { useFetchLlmModels } from '@/utils/hooks/useFetchLlmModels'

import { LlmModel, LlmModels } from '../types'

type UseLlmModelArgument = {
  token: string
  initializeChatHistory: () => void
  isExceptImageModel?: boolean
}

type ChangeLlmModelArgument = {
  llmModel: LlmModel
  selectedIndex: boolean
}

export type ChangeLlmModelType = (object: ChangeLlmModelArgument) => void

type handleModalSubmitArgument = {
  llmModel: LlmModel
  promptId?: string
}

export type HandleModalSubmitArgumentType = (object: handleModalSubmitArgument) => void

type CanChangeLlmModelArgument = {
  llmModel: LlmModel
}

// LlmModelsからIDが1のものを返す
const getLlmModelWithIdOne = (llmModels: LlmModels) => {
  return llmModels.find((item) => item.id === 1)
}

export const useLlmModel = ({
  token,
  initializeChatHistory,
  isExceptImageModel = false,
}: UseLlmModelArgument) => {
  const [filterModels, setFilterModels] = useState<LlmModels>([])
  const [selectedLlmModel, setSelectedLlmModel] = useState<LlmModel>()
  const [allowFileAttachments, setAllowFileAttachments] = useState<boolean>(false)

  const {
    isOpen: isIndexModalOpen,
    handleOpen: handleIndexModalOpen,
    handleClose: handleIndexModalClose,
  } = useDisclosure()

  const { isOpen, handleOpen, handleClose } = useDisclosure()

  const { data, isFetched } = useFetchLlmModels(token)

  const router = useRouter()

  const pathName = usePathname()
  const searchParams = useSearchParams()

  // データ取得が完了したらid:1の値を選択済みにする
  // localStorageにllmModelがある場合は、そのllmModelを選択済みにする
  // ※チャット中に別会社に切り替えた場合リロードが入りstateを維持できないためlocalStorageで対応
  useEffect(() => {
    const llmModel = localStorage.getItem('llmModel')
      ? (JSON.parse(localStorage.getItem('llmModel')!) as LlmModel)
      : undefined

    if (isFetched) {
      handleSetFilterModels()
      if (llmModel) {
        setSelectedLlmModel(llmModel)
        localStorage.removeItem('llmModel')
      } else {
        if (pathName === '/') setSelectedLlmModel(getLlmModelWithIdOne(data))
      }
    }
    // HOMEチャットからModel切り替えをした時に動かない
    // pathNameでURLの変更を検知して動くようにすることで解決
    // searchParams:?id={promptId}の切りかわりも検知しないとprompt新規→新規チャットでモデルが初期化されない
  }, [isFetched, data, pathName, searchParams])

  /**
   * 選択されているLLMモデルを変更する。別の会社のモデルに変更することはできない。
   * @param llmModel
   * @returns
   */
  const changeLlmModel = ({ llmModel, selectedIndex }: ChangeLlmModelArgument) => {
    if (selectedIndex && llmModel.id === 90) {
      handleIndexModalOpen()
      return
    }

    if (!canChangeLlmModel({ llmModel })) {
      // Index選択済みで画像生成を選んだ場合は、選択を外すように促す
      handleOpen()
      return
    }

    setSelectedLlmModel(llmModel)
  }

  /**
   * 選択されているLLMモデルを確認なしで変更する
   * @param llmModel
   */
  const handleModalSubmit = ({ llmModel, promptId }: handleModalSubmitArgument) => {
    localStorage.setItem('llmModel', JSON.stringify(llmModel))
    initializeChatHistory()
    handleClose()
    if (promptId) router.push(`/?id=${promptId}`)
    else router.push('/')
  }

  /**
   * LLMモデルが変更可能か確認する
   * @param llmModel
   * @returns
   */
  const canChangeLlmModel = ({ llmModel }: CanChangeLlmModelArgument) => {
    // 別の会社に変更しようとしたら処理を停止する
    // 既存チャットのみが対象
    return (
      pathName === '/' || (selectedLlmModel && selectedLlmModel.group === llmModel.group)
    )
  }

  /**
   * IDのLLMモデルに変更する
   * @param id
   */
  const changeLlmModelFromId = useCallback(
    (id: number) => {
      const llmModel = data.find((llmModel) => llmModel.id === id)
      llmModel && setSelectedLlmModel(llmModel)
    },
    [data],
  )

  /*
   * LLMモデル選択状態の初期化
   */
  const initializeLlmModelSelecting = () => {
    setSelectedLlmModel(getLlmModelWithIdOne(data))
  }

  const handleSetFilterModels = useCallback(() => {
    const models = data.filter((f) => f.group !== 90)
    setFilterModels(models)
  }, [data])

  /**
   * ファイル添付が許可されたモデルか
   * @returns
   */
  const changeFileAttachments = () => {
    // TODO:マジックナンバーをやめたい
    // 1 = OpenAI / 2 = Google / 3 = ANTHROPIC / 90 = 画像生成
    setAllowFileAttachments(
      selectedLlmModel?.group === 2 || selectedLlmModel?.group === 3,
    )
  }

  useEffect(() => {
    if (selectedLlmModel) changeFileAttachments()
  }, [selectedLlmModel])

  return {
    llmModels: !isExceptImageModel ? data : filterModels,
    selectedLlmModel,
    allowFileAttachments,
    isOpen,
    handleClose,
    isIndexModalOpen,
    handleIndexModalOpen,
    handleIndexModalClose,
    setSelectedLlmModel,
    changeLlmModel,
    handleModalSubmit,
    changeLlmModelFromId,
    initializeLlmModelSelecting,
  }
}
